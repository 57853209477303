<template>
  <div class="wrap__content">
    <div class="section__banner">
      <div class="container">
        <div class="banner__row">
          <div class="banner__left">
            <h1
              class="banner__left--title section__title"
              v-html="$t('main.banner.title')"
            ></h1>
            <div class="banner__left--desc">
              {{ $t('main.banner.desc') }}
            </div>
            <div class="section--link">
              <a :href="'/' + $i18n.locale + '/registration'">{{
                $t('system.registration')
              }}</a>
            </div>
          </div>
          <div class="banner__right">
            <picture>
              <source
                type="image/webp"
                srcset="../assets/img/banner__img.webp"
              />
              <source type="image/png" srcset="../assets/img/banner__img.png" />
              <img src="../assets/img/banner__img.png" />
            </picture>
          </div>
        </div>
      </div>
    </div>

    <div class="section section__programm">
      <div class="container">
        <div class="section__title" v-html="$t('main.programm.title')"></div>

        <div class="section--desc" v-html="$t('main.programm.desc')"></div>
        <!--
		<div class="section--link">
		  <a href="#">{{ $t('main.banner.link') }}</a>
		</div>
		-->
      </div>
    </div>

    <div class="section section__subsidizing">
      <div class="container">
        <div class="section__title" v-html="$t('main.subsidizing.title')"></div>

        <div class="d-none d-lg-block">
          <div class="subsidizing__block">
            <div class="row">
              <div class="col-lg-6">
                <div class="subsidizing__item">
                  <div class="subsidizing__item--top">
                    <div
                      class="subsidizing__item--title"
                      v-html="$t('main.subsidizing.item_kids.title')"
                    ></div>
                    <div
                      class="subsidizing__item--desc"
                      v-html="$t('main.subsidizing.item_kids.desc')"
                    ></div>
                  </div>
                  <div class="section--link">
                    <a :href="'/' + $i18n.locale + '/registration'">{{
                      $t('system.apply')
                    }}</a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="subsidizing__item">
                  <div class="subsidizing__item--top">
                    <div
                      class="subsidizing__item--title"
                      v-html="$t('main.subsidizing.item_foreign.title')"
                    ></div>
                    <div
                      class="subsidizing__item--desc"
                      v-html="$t('main.subsidizing.item_foreign.desc')"
                    ></div>
                  </div>
                  <div class="section--link">
                    <a
                      style="
                        color: #bababa !important;
                        background: #dbdbdb !important;
                        box-shadow: 1px 10px 22px rgb(219 219 219 / 80%) !important;
                        cursor: default !important;
                      "
                      >{{ $t('system.apply') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <swiper
            class="subsidizing__block subsidizing--slider"
            :options="subsidizingOption"
          >
            <swiper-slide class="swiper-slide">
              <div class="subsidizing__item">
                <div class="subsidizing__item--top">
                  <div
                    class="subsidizing__item--title"
                    v-html="$t('main.subsidizing.item_kids.title')"
                  ></div>
                  <div
                    class="subsidizing__item--desc"
                    v-html="$t('main.subsidizing.item_kids.desc')"
                  ></div>
                </div>
                <div class="section--link">
                  <a :href="'/' + $i18n.locale + '/registration'">{{
                    $t('system.apply')
                  }}</a>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="subsidizing__item">
                <div class="subsidizing__item--top">
                  <div
                    class="subsidizing__item--title"
                    v-html="$t('main.subsidizing.item_foreign.title')"
                  ></div>
                  <div
                    class="subsidizing__item--desc"
                    v-html="$t('main.subsidizing.item_foreign.desc')"
                  ></div>
                </div>
                <div class="section--link">
                  <a
                    style="
                      color: #bababa !important;
                      background: #dbdbdb !important;
                      box-shadow: 1px 10px 22px rgb(219 219 219 / 80%) !important;
                      cursor: default !important;
                    "
                    >{{ $t('system.apply') }}</a
                  >
                </div>
              </div>
            </swiper-slide>

            <div
              class="subsidizing-pagination slider-pagination"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </div>

    <div class="section section__tasks">
      <div class="container">
        <div class="section__title" v-html="$t('main.tasks.title')"></div>

        <div class="d-none d-lg-block">
          <div class="tasks__list">
            <div class="row">
              <div class="col-lg-3">
                <div class="task__item">
                  <div class="task__item--img">
                    <img src="../assets/img/tasks/task-1.png" />
                  </div>
                  <div class="task__item--text">
                    {{ $t('main.tasks.list.task1') }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="task__item">
                  <div class="task__item--img">
                    <img src="../assets/img/tasks/task-2.png" />
                  </div>
                  <div class="task__item--text">
                    {{ $t('main.tasks.list.task2') }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="task__item">
                  <div class="task__item--img">
                    <img src="../assets/img/tasks/task-3.png" />
                  </div>
                  <div class="task__item--text">
                    {{ $t('main.tasks.list.task3') }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="task__item">
                  <div class="task__item--img">
                    <img src="../assets/img/tasks/task-4.png" />
                  </div>
                  <div class="task__item--text">
                    {{ $t('main.tasks.list.task4') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <swiper class="tasks__list tasks--slider" :options="tasksOption">
            <swiper-slide class="swiper-slide">
              <div class="task__item">
                <div class="task__item--img">
                  <img src="../assets/img/tasks/task-1.png" />
                </div>
                <div class="task__item--text">
                  {{ $t('main.tasks.list.task1') }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="task__item">
                <div class="task__item--img">
                  <img src="../assets/img/tasks/task-2.png" />
                </div>
                <div class="task__item--text">
                  {{ $t('main.tasks.list.task2') }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="task__item">
                <div class="task__item--img">
                  <img src="../assets/img/tasks/task-3.png" />
                </div>
                <div class="task__item--text">
                  {{ $t('main.tasks.list.task3') }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="task__item">
                <div class="task__item--img">
                  <img src="../assets/img/tasks/task-4.png" />
                </div>
                <div class="task__item--text">
                  {{ $t('main.tasks.list.task4') }}
                </div>
              </div>
            </swiper-slide>

            <div
              class="tasks-pagination slider-pagination"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      subsidizingOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.subsidizing-pagination',
          clickable: true,
        },
      },
      tasksOption: {
        slidesPerView: 1.5,
        spaceBetween: 0,
        pagination: {
          el: '.tasks-pagination',
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1.2,
          },
          400: {
            slidesPerView: 1.2,
          },
          520: {
            slidesPerView: 1.5,
          },
        },
      },
    }
  },
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Басты бет | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'Главная | Специализированная информационная система «eQonaq»'
              : 'Home | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>
